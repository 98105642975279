/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { headingPrimary, subtitleText } from 'src/components/common/Typography'

import Election from 'src/assets/election.svg'
import { mq } from 'src/utils/mq'

const wrapper = css`
  padding: 16px;
  position: relative;

  ${mq.md} {
    padding: 64px;
  }
`

const titleStyle = css`
  ${headingPrimary}
  margin-bottom: 16px;
`

const titleBlockStyles = css`
  margin: 0 0 48px;
  text-align: center;

  ${mq.md} {
    margin: 0 0 58px;
  }
`

const avatarImageStyles = css`
  margin: 0 4px 0 8px;
  height: 24px;
  border-radius: 50%;
  vertical-align: middle;
`

const regardsStyles = css`
  line-height: 24px;
  text-align: right;
`

const checkedStyles = css`
  margin: 0 0 24px;
  width: 128px;
  height: 100px;
`

export const ThankYouContent: React.FC = () => {
  const olegAvatar =
    'https://squidex.rabbitpeepers.work/api/assets/4f41e636-6ad6-462d-86ca-b3e825435f21/?width=64&height=64&quality=80'
  const mykolaAvatar =
    'https://squidex.rabbitpeepers.work/api/assets/ac33032b-0571-402c-b423-96f41bdc7b5a/?width=64&height=64&quality=80'

  return (
    <div css={wrapper}>
      <div css={titleBlockStyles}>
        <Election css={checkedStyles} />
        <h1 css={titleStyle}>Thank you!</h1>
        <p css={subtitleText}>
          We appreciate your interest. All communication with our clients is
          handled by the founders directly.
          <br />
          We work in GMT-3 timezone, which means we're online from 01:00 AM to
          01:00 PM <i>Eastern Daylight Time</i>
          .
          <br />
          Usually, we respond within <b>3 business hours.</b>
        </p>
      </div>
      <div>
        <p css={regardsStyles}>
          King regards,
          <img src={olegAvatar} alt="Oleh Tsymbal" css={avatarImageStyles} />
          Oleh and
          <img src={mykolaAvatar} alt="Mykola V" css={avatarImageStyles} />
          Mykola
          <br />
          from <b>RabbitPeepers</b>
        </p>
      </div>
    </div>
  )
}
