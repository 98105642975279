import { Layout } from 'src/components/Layout/Layout'
import { PageProps } from 'gatsby'
import React from 'react'
import { ThankYou } from 'src/components/ThankYou/ThankYou'

const ThankYouPage: React.FC<PageProps<{}>> = () => {
  return (
    <Layout title="Thank you | RabbitPeepers">
      <ThankYou />
    </Layout>
  )
}

export default ThankYouPage
