/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { Container } from 'src/components/common/Container'
import { ShadowBox } from 'src/components/common/ShadowBox'
import { ThankYouContent } from 'src/components/ThankYou/ThankYouContent'
import { mq } from 'src/utils/mq'

const wrapperStyles = css`
  margin-bottom: 80px;

  ${mq.md} {
    margin-bottom: 0;
    padding: 0 16px;
  }

  ${mq.lg} {
    padding: 0 24px 0 0;
  }

  ${mq.xl} {
    padding: 0 50px 0 0;
  }
`

export const ThankYou: React.FC = () => {
  return (
    <Container css={wrapperStyles}>
      <ShadowBox>
        <ThankYouContent />
      </ShadowBox>
    </Container>
  )
}
